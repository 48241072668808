<template>
  <teleport to="head">
    <title>Oops! – PLANTA</title>
  </teleport>

  <el-menu mode="horizontal"
       class="main_menu centered"
       background-color="#76c043"
       text-color="#fff"
       active-text-color="#fff">

    <el-menu-item><a href="/"><img src="@/assets/m-logo.png" style="height: 33%"></a></el-menu-item>
  </el-menu>

  <section id="top_benefit"></section>

  <section id="main" class="centered">
    <h1>Oops! 😢</h1>
    <p>Вибачте, щось пішло не так.</p>
    <p>Спробуйте перезавантажити сторінку через декілька хвилин, очистити куки або інший бразуер.</p>
    <p></p>
    <h3>🌿</h3>
  </section>

  <section id="footer">
    <div class="centered">
      <h3 style="text-align: center;">Контакти</h3>
        <ul>
            <li><a class="extls" href="tel:+380985550122" title="Kyivstar">(098) 555 01 22</a></li>
            <li><a class="extls" href="tel:+380660090122" title="Vodafone">(066) 009 01 22</a></li>
            <li><a class="extls" href="tel:+380930500122" title="Lifecell">(093) 050 01 22</a></li>
            <li>&nbsp;</li>
            <li><a class="extls" target="_blank" href="https://www.instagram.com/planta.eco/">Instagram</a></li>
            <li><a class="extls" target="_blank" href="sgnl://signal.me/#p/+380985550122" title="Signal">Signal</a></li>
            <li><a class="extls" target="_blank" href="viber://chat?number=380985550122" title="Viber">Viber</a></li>
            <li><a class="extls" style="text-decoration-line: line-through;" target="_blank" href="https://t.me/planta_eco">Telegram</a>&nbsp;<a class="extls" style="text-decoration-line: line-through;" target="_blank" href="https://kremlingram.org/ua"><el-button icon="el-icon-info" type="text" style="color: #888;" /></a></li>
        </ul>

    </div>
  </section>

</template>

<script>

</script>

<style src="@/main.css"></style>